import { DanikaResult } from "components/atoms/DanikaMetrics/types";
import { getMatomoInstance } from "components/context/MatomoContext";
import { config } from "config";
import { JsonResponseError, store } from "contorller";
import { authController } from "contorller/auth/controller";
import { Auther } from "contorller/auth/types";
import { ElasticPageResult } from "contorller/elastic/types";
import { unset } from "lodash";
import { PortfolioActions } from "./redux";
import {
  CodeResponse,
  createPortfolioRequest,
  Portfolio,
  timeShare,
} from "./types";

export interface MetricsOptions {
  proxy: boolean;
  duration_years: number;
  duration_rolling: number;
  free_risk: number;
}
export interface MetricsResult {
  error: boolean;
  code: string;
  text: string;
  payload: DanikaResult;
}

export interface PortfolioController {
  acceptCode(code: string): Promise<Portfolio>;
  share(id: string, deadline: timeShare): Promise<CodeResponse>;
  resolveCode(code: string): Promise<Portfolio>;
  copy(id: string, name: string): Promise<Portfolio>;
  newPortfolio(): Promise<void>;
  isNewPortfolio(portfolio: Portfolio): boolean;
  createPortfolio(request: createPortfolioRequest): Promise<Portfolio>;
  deletePortfolio(id: string): Promise<void>;
  resetPortfolios(): Promise<void>;
  listPortfolios(queryString?: string): Promise<Portfolio[]>;
  nextPage(): Promise<void>;
  getPorfolio(
    id: string,
    disableProxy?: boolean,
    disableActions?: boolean
  ): Promise<Portfolio | null>;
  getPortfolioMetrics(
    portfolio: Portfolio,
    options: MetricsOptions,
    back_to_date: string | null
  ): Promise<MetricsResult | null>;
  editPortfolio(id: string, portfolio: Portfolio): Promise<void>;
  saveWithName(portfolio: Portfolio, name: string): Promise<Portfolio>;
}

export const portfolioController = (): PortfolioController =>
  concreteClassPortfolio.instance(authController().getAuther());

const url = config().URL + "/portfolio";
// const danikaUrl = `https://${config().DANIKA_HOST}`;

class concreteClassPortfolio implements PortfolioController {
  private auther: Auther;

  //   public iis: IISController
  private constructor(auther: Auther) {
    this.auther = auther;
  }
  saveWithName(portfolio: Portfolio, name: string): Promise<Portfolio> {
    let p = { ...portfolio, name: name };
    unset(p, "id");
    return this.createPortfolio(p);
  }

  isNewPortfolio(portfolio: Portfolio): boolean {
    return (
      portfolio.isDanika === true ||
      portfolio.id.length === 0 ||
      portfolio.id === "danika"
    );
  }

  async newPortfolio() {
    store.dispatch(PortfolioActions.newPortfolio());
  }

  async copy(id: string, name: string): Promise<Portfolio> {
    const response = await fetch(url + "/cp/" + id, {
      headers: await this.auther.getHeaderToken(),
      body: JSON.stringify({ name: name }),
      method: "POST",
    });

    if (response.status === 200) {
      const data: Portfolio = await response.json();
      store.dispatch(PortfolioActions.prependPortfolio({ portfolio: data }));
      return data;
    }
    const data: JsonResponseError = await response.json();
    store.dispatch(PortfolioActions.cp_error({ error: data }));
    throw new Error(data.error);
  }
  async resetPortfolios() {
    store.dispatch(PortfolioActions.resetPortfolios());
  }

  async acceptCode(code: string): Promise<Portfolio> {
    const response = await fetch(url + "/accept/" + code, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: Portfolio = await response.json();
      store.dispatch(PortfolioActions.prependPortfolio({ portfolio: data }));
      return data;
    }

    const data: JsonResponseError = await response.json();
    store.dispatch(PortfolioActions.share_error({ error: data }));
    throw new Error(data.error);
  }

  async share(id: string, deadline: timeShare): Promise<CodeResponse> {
    const response = await fetch(url + "/share/" + id + "?time=" + deadline, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: CodeResponse = await response.json();
      return data;
    }
    const data: JsonResponseError = await response.json();
    store.dispatch(PortfolioActions.share_error({ error: data }));
    throw new Error(data.error);
  }

  async getPortfolioMetrics(
    portfolio: Portfolio,
    options: MetricsOptions,
    back_to_date: string | null
  ): Promise<MetricsResult | null> {
    const danikaUrl = store.getState().auth.danikaMaster
      ? `https://${config().DANIKA_MASTER_HOST}`
      : `https://${config().DANIKA_HOST}`;

    const response = await fetch(danikaUrl + "/ptf_metrics", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(await this.auther.getHeaderToken()),
      },
      method: "POST",
      body: JSON.stringify({
        ...options,
        ptf: portfolio,
        back_to_date,
      }),
    });
    if (response.status === 200) {
      return await response.json();
    }

    return null;
  }

  async resolveCode(code: string): Promise<Portfolio> {
    const response = await fetch(url + "/show/" + code, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: Portfolio = await response.json();
      return data;
    }

    const data: JsonResponseError = await response.json();
    store.dispatch(PortfolioActions.share_error({ error: data }));
    throw new Error(data.error);
  }

  async createPortfolio(request: createPortfolioRequest): Promise<Portfolio> {
    console.log(
      "[creazione portafoglio] provo  a creare un portfolio:",
      request
    );
    const response = await fetch(url + "/", {
      headers: await this.auther.getHeaderToken(),
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      const data: Portfolio = await response.json();
      store.dispatch(PortfolioActions.setLastidCreated({ id: data.id }));
      store.dispatch(PortfolioActions.prependPortfolio({ portfolio: data }));
      store.dispatch(PortfolioActions.resetMakerPortfolio());

      console.log("[creazione portafoglio] creato:", data);
      return data;
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(PortfolioActions.error({ error: data }));
      console.error("[creazione portafoglio] ho ottenuto un errore:", data);
      throw new Error(`${data.code} - ${data.error}`);
    }
  }

  async deletePortfolio(id: string): Promise<void> {
    console.log("[delete portfolio] provo a cancellare questo portfolio:", id);
    const response = await fetch(url + "/delete/" + id, {
      headers: await this.auther.getHeaderToken(),
      method: "POST",
    });

    if (response.status === 200) {
      store.dispatch(PortfolioActions.deletePortfolio({ id: id }));
      console.log("[delete portfolio] cancellato senza errori: ", id);
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(PortfolioActions.portfolioError({ id: id, error: data }));
      console.error("[delete portfolio] ho ottenuto questo errore:", data);
    }
  }

  async editPortfolio(id: string, portfolio: Portfolio): Promise<void> {
    const response = await fetch(url + "/edit/" + id, {
      headers: await this.auther.getHeaderToken(),
      method: "POST",
      body: JSON.stringify(portfolio),
    });
    if (response.status === 200) {
      const data = await response.json();
      //console.log("update with that", portfolio, "response", data)
      store.dispatch(
        PortfolioActions.portfolioInViewChange({ portfolio: data })
      );
      store.dispatch(
        PortfolioActions.findAndReplacePortfolio({ portfolio: data })
      );
    } else {
      const data = await response.json();
      console.error(data);
      throw new Error(`${data.code} - ${data.error}`);
    }
  }

  private page: number = 0;
  private block: boolean = false;
  async nextPage(): Promise<void> {
    if (this.block === true) return;
    this.page++;
    this.request.from = 10 * this.page;
    const response = await fetch(config().ES + "/portfolios-v2/_search", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(this.request),
    });

    await this.loadPortfolio(response, true);
  }

  private request: { from: number; size: number; sort: any; query: any } = {
    from: 0,
    size: 0,
    query: {},
    sort: undefined,
  };
  private async loadPortfolio(
    response: Response,
    next: boolean
  ): Promise<Portfolio[]> {
    if (response.status === 200) {
      let data: ElasticPageResult<Portfolio> = await response.json();
      console.log("[RESPONSE load portfolios]", data);
      if (data.hits.hits.length === 0) {
        this.block = true;
      }
      console.log("[load Portfolio]: ", data);
      if (next) {
        store.dispatch(
          PortfolioActions.appendPortfolios({
            portfolios: data.hits.hits.map((e) => e._source),
          })
        );
      } else {
        store.dispatch(
          PortfolioActions.setPortfolios({
            portfolios: data.hits.hits.map((e) => e._source),
          })
        );
      }
      return data.hits.hits.map((e) => e._source);
    } else {
      const data: JsonResponseError = await response.json();
      console.log(data);
      store.dispatch(PortfolioActions.error({ error: data }));
      return [];
    }
  }
  private controllerSignal = new AbortController();
  async listPortfolios(s?: string): Promise<Portfolio[]> {
    this.controllerSignal.abort();
    this.controllerSignal = new AbortController();
    this.page = 0;
    try {
      const auth = store.getState().auth.user;
      if (!auth) return [];
      const must = [
        {
          exists: {
            field: "viewers",
          },
        },
        {
          match: {
            "viewers.keyword": {
              query: auth.uid,
            },
          },
        },
      ];
      const should: any[] = [];
      if (s && s !== "") {
        for (let i of [
          { query: "name", boost: 3 },
          { query: "description", boost: 1 },
          { query: "funds.fund.name", boost: 2 },
          { query: "funds.fund.isin", boost: 4 },
        ]) {
          should.push({
            match: {
              [i.query]: {
                query: s,
                fuzziness: 2,
                boost: i.boost,
              },
            },
          });
        }
      }
      this.request = {
        from: this.page,
        size: 2000,
        query: {
          bool: {
            must: must,
            should: should,
          },
        },

        sort:
          (s?.length ?? 0) === 0
            ? {
                "name.keyword": {
                  order: "asc",
                },
              }
            : {},
      };
      console.log("[REQUEST load-portfolios]", s, this.request);
      const response = await fetch(config().ES + "/portfolios-v2/_search", {
        method: "POST",
        signal: this.controllerSignal.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "firebase " + (await authController().getAuther().getToken()),
        },
        body: JSON.stringify(this.request),
      });

      console.log("[load portfolios]", response.status);
      return await this.loadPortfolio(response, false);
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  private static _instance: concreteClassPortfolio;

  async getPorfolio(
    id: string,
    disableProxy?: boolean,
    disableAction?: boolean
  ): Promise<Portfolio | null> {
    const response = await fetch(url + "/" + id, {
      headers: await this.auther.getHeaderToken(),
      method: "POST",
    });

    if (response.status === 200) {
      const data: Portfolio = await response.json();
      console.log("[Portfolio response] ", data.name, data);
      getMatomoInstance()?.trackEvent("Portfolio", "Opened", data.name, 0, {
        id: data.id,
      });
      if (disableAction !== true) {
        let portfolio = data;
        // portfolio.funds = portfolio.funds;
        store.dispatch(
          PortfolioActions.portfolioSelect({ portfolio, disableProxy })
        );
      }
      return data;
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(PortfolioActions.error({ error: data }));
      return null;
    }
  }

  static instance(auther: Auther): concreteClassPortfolio {
    if (!concreteClassPortfolio._instance) {
      concreteClassPortfolio._instance = new concreteClassPortfolio(auther);
    }
    return concreteClassPortfolio._instance;
  }
}

export function filteredPortfolio(
  portfolios: Portfolio[],
  query: string
): Portfolio[] {
  if (portfolios.length === 0) {
    portfolioController().listPortfolios(query);
  }
  return portfolios;
}
