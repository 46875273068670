import { Colors } from "@blueprintjs/core";
import { usePrinting } from "components/context/PrintContext";
import {
  createSimulationHistograms,
  getColorFn,
} from "components/organismes/Graph/utils";
import { useUpAndDowns } from "components/queries/upAndDowns";
import { getColorPaletteAtIndex } from "config";
import { RootState } from "contorller";
import { Portfolio } from "contorller/portfolio/types";
import { SettingsState } from "contorller/settings/redux";
import { PICState } from "contorller/simulations/PIC/redux";
import { compact, last } from "lodash";
import { isEmpty } from "lodash/fp";
import { DateTime } from "luxon";
import Matrix from "ml-matrix";
import type { Annotations, PlotData, Shape } from "plotly.js";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  AMOUNT,
  CLOSES,
  DATES,
  INVESTED,
  INVESTED_FIXED_RATE,
} from "simulator/types";
import { getAbsoluteYield } from "./earnTable";

type PicCharts = {
  histogram: PlotData[];
  line: PlotData[];
  revision: number;
  quote: PlotData[];
  additionals: PlotData[];
  shapes: Shape[];
  annotations: Annotations[];
};

export function usePicCharts(
  portfolio: Portfolio | undefined,
  rollingData: Matrix[],
  lineData: Matrix | null,
  fundsLineData: Matrix[] | null,
  additionalsLineData: Matrix[] | null,
  window: { start: DateTime; end: DateTime },
  isRealPic: boolean,
  showInterestsCompare: boolean,
  interestCompareFundId?: string 
): PicCharts {
  const { t } = useTranslation();
  const currencyFormatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [revision, setRevision] = useState(0);
  const { data: upAndDownsData } = useUpAndDowns(interestCompareFundId);
  const { showMeanPerfomance, logarithmic, opacity, colorBlindness } = useSelector<
    RootState,
    SettingsState
  >((s) => s.settings);
  const pic = useSelector<RootState, PICState>((s) => s.pic);
  const { spots } = pic;
  const applyScale = (values: number[]) => {
    if (logarithmic) {
      const scaleValue = values[0] / Math.log(values[0]);
      return values.map((v) => Math.log(v) * scaleValue);
    } else {
      return values;
    }
  };
  const x = useMemo(() => {
    return (
      lineData
        ?.getRow(DATES)
        ?.map(
          (d) =>
            `${DateTime.fromSeconds(d).toLocaleString(DateTime.DATE_SHORT)}`
        ) ?? []
    );
  }, [lineData]);

  const { graphOptions } = pic;
  const { fundHoverId, fundIdsHidden, showSingleFunds } = graphOptions;

  const { line } = useMemo<Pick<PicCharts, "line">>(() => {
    const hoverinfo = !showSingleFunds ? "x+text+name" : "none";
    let line: PlotData[] = [];
    if (lineData != null) {
      const isInvestedHover = fundHoverId == null || fundHoverId == "invested";
      const y = applyScale(lineData.getRow(INVESTED));
      const invested =
        !fundIdsHidden.includes("invested") && isRealPic
          ? ({
              type: "scatter",
              name: "Investito",
              x,
              y,
              hoverinfo,
              text: y.map((d) => currencyFormatter.format(d)),
              // hovertemplate,
              opacity: isInvestedHover ? 1 : 0.2,
              marker: {
                opacity,
                color: getColorPaletteAtIndex(1),
              },
            } as PlotData)
          : null;
      const isPurchasingHover =
        fundHoverId == null || fundHoverId == "invested-fixed-rate";
      const fixedRateY = applyScale(lineData.getRow(INVESTED_FIXED_RATE));
      const purchasingPower =
        !fundIdsHidden.includes("invested-fixed-rate") && isRealPic
          ? ({
              type: "scatter",
              name: "Investito Tasso Fisso",
              x,
              y: fixedRateY,
              hoverinfo,
              opacity: isPurchasingHover ? 1 : 0.2,
              text: fixedRateY.map((d) => currencyFormatter.format(d)),
              marker: {
                color: getColorPaletteAtIndex(2),
              },
            } as PlotData)
          : null;
      const isPortfolioHover =
        fundHoverId == null || fundHoverId == portfolio?.id;
      const maxOpacity = 1 - opacity;
      const amountY = applyScale(lineData.getRow(AMOUNT));
      const portfolios = !fundIdsHidden.includes(portfolio?.id ?? "")
        ? ({
            type: "scatter",
            name: portfolio?.name ?? "Portafoglio",
            x,
            y: amountY,
            hoverinfo,
            opacity: isPortfolioHover ? 1 : 0.2,
            yaxis: {},
            text: amountY.map((d) => currencyFormatter.format(d)),
            marker: {
              color: getColorPaletteAtIndex(0),
            },
          } as PlotData)
        : null;
      const funds = pic.graphOptions.showSingleFunds
        ? fundsLineData?.map((d, index) => {
            const fund = portfolio?.funds[index];
            const isHover = fundHoverId == fund?.id;
            const isVisible = fundHoverId == null || isHover;
            const isHidden = fundIdsHidden.includes(
              portfolio?.funds[index]?.id ?? ""
            );
            if (isHidden) {
              return null;
            }
            const fundLineY = applyScale(d.getRow(AMOUNT));
            return {
              type: "scatter",
              name: fund?.fund?.name,
              x,
              opacity: isVisible ? 1 : 0.2,
              y: fundLineY,
              marker: {
                color: getColorPaletteAtIndex(3 + index),
              },
              text: fundLineY.map((d) => currencyFormatter.format(d)),
              hoverinfo,
            } as PlotData;
          })
        : null;
      line = compact([portfolios, purchasingPower, invested, ...(funds ?? [])]);
    }

    setRevision((p) => p + 1);

    return { line };
  }, [
    pic,
    fundHoverId,
    opacity,
    showSingleFunds,
    fundIdsHidden,
    lineData,
    logarithmic,
  ]);
  const printingCtx = usePrinting();
  const formatter = new Intl.NumberFormat("IT-it", {
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
  });
  const { histogram } = useMemo<Pick<PicCharts, "histogram">>(() => {
    const positive = getColorFn("positive");
    const negative = getColorFn("negative");

    const percentFormatter = new Intl.NumberFormat("it", {
      style: "percent",
      minimumFractionDigits: 2,
    });

    const histogram = createSimulationHistograms(
      rollingData.map((d) => ({
        absolute: getAbsoluteYield(d),
        start: DateTime.fromSeconds(d.get(DATES, 0)).endOf("day").toJSDate(),
        end: DateTime.fromSeconds(d.get(DATES, d.columns - 1))
          .startOf("day")
          .toJSDate(),
      })),
      { positive, negative },
      t,
      {
        showMeanPerfomance,
        window,
        opacity,
        isPrinting: printingCtx.isPrinting,
      }
    ) as PlotData[];

    setRevision((p) => p + 1);

    return { histogram };
  }, [
    rollingData,
    showMeanPerfomance,
    window,
    printingCtx.isPrinting,
    opacity,
  ]);
  const simStartDate = DateTime.fromSeconds(lineData?.get(DATES,0) ?? 0)
  const simEndDate = DateTime.fromSeconds(lineData?.get(DATES,lineData.columns-1) ?? 0)
  const winStartDate = window.start
  const winEndDate = window.end
  const startDateOffsetIndex = Math.floor(Math.max(0, winStartDate.diff(simStartDate, "days").days))
  const endDateOffsetIndex = Math.floor(Math.min((lineData?.columns ?? 0)-1 -simEndDate.diff(winEndDate, "days").days, (lineData?.columns ?? 0)))
  const quote = useMemo(() => {
    let data: Matrix | null = lineData;
    let y: number[] = [];
    if (!isEmpty(pic.spots.selectedFundId)) {
      const index = portfolio?.funds.findIndex(
        (d) => d.id === pic.spots.selectedFundId
      );
      if (index != null) {
        data = fundsLineData?.[index] ?? lineData;
        y = data?.getRow(CLOSES).slice(startDateOffsetIndex,endDateOffsetIndex) ?? [];
      }
    } else {
      y =
        fundsLineData
          ?.reduce((prev: Matrix | null, fund, index) => {
            const val = fund
              .getRowVector(CLOSES)
              .multiply(portfolio?.funds?.[index]?.percentage ?? 0);
            if (prev == null) {
              return val;
            } else {
              return Matrix.add(prev, val);
            }
          }, null)
          ?.to1DArray().slice(startDateOffsetIndex,endDateOffsetIndex) ?? [];
    }
    const operations = spots.operations.filter(
      (f) =>
        isEmpty(pic.spots.selectedFundId) ||
        pic.spots.selectedFundId === f.fundId
    );
    const spotX = operations.map((d) => DateTime.fromSeconds(d.unix));
    const slicedX = x.slice(startDateOffsetIndex, endDateOffsetIndex);

    return [
      {
        type: "scatter",
        name: t("Valore di Quota"),
        x:slicedX,
        y: applyScale(y),
        hoverinfo: "y+x" as any,
        marker: {
          color: getColorPaletteAtIndex(0),
        },
      },
      {
        type: "scatter",
        mode: "markers",
        name: t("Aggiuntivi/Prelievi"),
        x: spotX.map((d) => d.toLocaleString()),
        y: spotX.map((d) => {
          const startDate = DateTime.fromSeconds(data?.get(DATES, 0) ?? 0);
          const index = Math.ceil(d.diff(startDate, "days").days);
          return y?.[index];
        }),
        marker: {
          symbol: "circle",
          // color: "transparent",
          color: spots.operations.map((e) => (e.money < 0 ? (colorBlindness === true ? Colors.GOLD3 : Colors.RED3) : (colorBlindness === true ? Colors.BLUE3 : Colors.GREEN3))),
          // line: {
          //   color: getColorPaletteAtIndex(1),
          //   width: 3,
          // },
          size: 10,
        },
        text: spots.operations.map((e) =>
          e.money < 0
            ? t("Prelievo di") + ` ${formatter.format(e.money)}`
            : t("Investimento di") + ` ${formatter.format(e.money)}`
        ),
      } as unknown,
    ] as PlotData[];
  }, [x, portfolio, spots.selectedFundId, spots.operations, window]);

  const additionals = useMemo(() => {
    return (
      additionalsLineData?.map((line, index) => {
        let offset = 0;
        if (index > 0 && index <= spots.operations.length) {
          // const spotDate = DateTime.fromSeconds(
          //   spots.operations[index - 1].unix
          // );
          // offset = Math.ceil(spotDate.diff(window.start, "days").days);
        }

        const y = line.getRow(AMOUNT);

        return {
          type: "scatter",
          x: line
            .getRow(DATES)
            .map((d) => DateTime.fromSeconds(d).startOf("day").toJSDate()),
          name:
            index == 0
              ? t("Guadagno")
              : t("Guadagno con") + ` ${index}° ` + t("Agg/Prel"),
          hoverinfo: "text+x" as any,
          marker: {
            color: getColorPaletteAtIndex(index),
          },
          text: y.map((e, index) => {
            const val = line.get(AMOUNT, index) - line.get(INVESTED, index);
            return (
              t(val >= 0 ? "Guadagno" : "Perdita") +
              ` ${formatter.format(val)} + ` +
              t("Investito") +
              ` ${formatter.format(line.get(INVESTED, index))} = ` +
              t("Risultato") +
              ` ${formatter.format(line.get(AMOUNT, index))}`
            );
          }),
          y: y.map((d, index) => {
            if (index < offset) {
              return undefined;
            } else {
              return d - line.get(INVESTED, index);
            }
          }),
        } as PlotData;
      }) ?? []
    );
  }, [x, additionalsLineData, portfolio, spots.operations]);

  type Ret = {
    shapes: Shape[];
    annotations: Annotations[];
  };
  
  const { shapes, annotations } = useMemo<Ret>(() => {
    if (!showInterestsCompare || upAndDownsData?.values == null) {
      return { shapes: [], annotations: [] };
    }
    const total = upAndDownsData.values.length;
    const xStartDate = parsePlotXDate(x[0])
    const xEndDate = parsePlotXDate(x[x.length-1])
    // console.log("XXXXXXXXXXXXXXXXXXX",x)
    // console.log("START DATEE",xStartDate)
    // console.log("END DATEEE",xEndDate)
    return upAndDownsData.values.reduce<Ret>(
      (prev, val, index) => {
        
        const startIndex = Math.max(Math.ceil(
          DateTime.fromSeconds(val.Unix).diff(xStartDate, "days").days
        ), 0);
        const nextDate =
          upAndDownsData.values[index + 1]?.Unix ?? xEndDate.toSeconds();
        const endIndex = Math.ceil(
          DateTime.fromSeconds(nextDate).diff(xStartDate, "days").days
        );
        const visible = ((nextDate >= xStartDate.toSeconds()) && (val.Unix <= xEndDate.toSeconds()))
        let color:string;
        if (val.Close < 0) {
          color = colorBlindness === true ? Colors.BLUE3 : Colors.GREEN3;
        } else if (val.Close === 0) {
          color = Colors.LIGHT_GRAY3;
        } else {
          color = colorBlindness === true ? Colors.GOLD3 : Colors.RED3;
        }
        const x0 = x[(startIndex < 0 ? 0 : startIndex)]
        const x1 = (index === total - 1 ? last(x) : x[endIndex] == null ? last(x): x[endIndex]) as string
        const annotationsVisible = ((visible) && (x0 !== x[0]) && (x1 !== last(x)))
        // console.log(`-----------------RECT ${index}`)
        // console.log("unix.Start", val.Unix, "unix.End", upAndDownsData.values[index + 1]?.Unix);
        // console.log("Start", startIndex, "End", endIndex);
        // console.log("Is it visible?", visible)
        // console.log("X0", x0, "X1",x1)
        const shape: Partial<Shape> = {
          type: "rect",
          xref: "x",
          yref: "paper",
          x0: x0,
          y0: 0,
          x1: x1,
          y1: 1,
          fillcolor: color,
          opacity: 0.2,
          line: {
            width: 1,
            color: "rgba(0,0,0,0.2)",
          },
          visible:visible,
          
        };
       
        let annotation: Partial<Annotations> | null = null;
        if (val.Close !== 0) {
          annotation = {
            xref: "x",
            xanchor: "center",
            yref: "paper",
            x: x[startIndex + Math.round((x.indexOf(x1) - startIndex) / 2.0)],
            y: 0.975,
            text: `${val.Close}%`,
            font: {
              color: "black",
            },
            opacity: 0.7,
            visible:visible ,
            showarrow: false,
            textangle: "-90"
          };
        }

        return {
          shapes: [...prev.shapes, shape],
          annotations: compact([...prev.annotations, annotation]),
        } as Ret;
      },
      {
        shapes: [],
        annotations: [],
      }
    );
  }, [upAndDownsData, line, showInterestsCompare, x, colorBlindness]);

  return {
    line,
    quote,
    histogram,
    shapes,
    annotations,
    revision,
    additionals,
  };
}

function parsePlotXDate(date: string):DateTime{
  if (date != null){
    const date_array = date.split("/")
    const day = "0"+ date_array[0]
    const month = "0"+ date_array[1]
    const newDateString = day.slice(-2) + "/" + month.slice(-2) + "/" + date_array[2]
    return DateTime.fromFormat(newDateString, "dd/MM/yyyy")
  }
  return DateTime.now()
}