import { FundHistory } from "contorller/history/db";
import { compact } from "lodash";
import { DateTime, Duration, DurationLike, Interval } from "luxon";
import Matrix from "ml-matrix";
import pLimit from "p-limit";
import { scaleFundData } from "./fund";
import { PromiseWorker } from "./promise";
import { Operation, RelativeOperation, WorkerMessageType } from "./types";
import SimulatorWorker from "./worker.js?worker";

const worker = new PromiseWorker(new SimulatorWorker());

export async function rolling(opt: {
  relativeOperations: RelativeOperation[];
  rollingStartDate: DateTime;
  interval: DurationLike;
  duration: DurationLike;
  data: FundHistory[];
  deflateFundId?: string;
  onlyBounds?: boolean;
  fixedRate?: number;
  annualCommission?: number;
}): Promise<Matrix[]> {
  const {
    relativeOperations,
    rollingStartDate,
    interval,
    duration,
    data,
    deflateFundId,
    onlyBounds,
    fixedRate,
    annualCommission,
  } = opt;
  const startDates = data.map((d) => DateTime.fromSeconds(d.startDate));
  const maxStartDate = DateTime.max(...startDates);
  const endDates = data.map((d) => DateTime.fromSeconds(d.endDate));
  const endDate = DateTime.min(...endDates);
  // console.log(
  //   `[Rolling] Simulation start date is ${maxStartDate.toLocaleString()} to ${endDate.toLocaleString()}`
  // );

  const startDate = DateTime.max(rollingStartDate, maxStartDate);

  if (endDate <= startDate) {
    return [];
  }

  const rollingDates = Interval.fromDateTimes(
    startDate.startOf("day"),
    endDate.minus(duration)
  ).splitBy(interval);
  const name = `Rolling ${rollingDates.length}`;
  console.time(name);

  const limit = pLimit(8);

  const result = await Promise.all(
    rollingDates.map(async ({ start }) =>
      limit(() => {
        const end = start
          .plus(Duration.fromObject({ days: 1 }).plus(duration))
          .startOf("day");
        // console.log(
        //   `[Rolling] Rolling starts ${start.toLocaleString()} to ${end.toLocaleString()}`
        // );

        if (end > endDate.plus({ days: 1 }) || start < startDate) {
          return null;
        }

        const payload = convertRollingData(
          data,
          start.startOf("day"),
          end.startOf("day"),
          relativeOperations
        );

        return worker
          .run({
            type: WorkerMessageType.RUN_PIC,
            payload: {
              ...payload,
              deflateFundId,
              onlyBounds,
              annualCommission,
              fixedRate,
            },
          })
          .then((result) => {
            if (result.type !== WorkerMessageType.PIC_RESULT) {
              throw new Error("Unexpected message type");
            }
            return new Matrix(result.payload.result.data);
          });
      })
    )
  );
  console.timeEnd(name);

  return compact(result);
}

export function convertRollingData(
  data: FundHistory[],
  start: DateTime,
  end: DateTime,
  relativeOperations: RelativeOperation[]
): {
  data: FundHistory[];
  operations: Operation[];
} {
  const rollingDatas = data.map((d) =>
    scaleFundData(d, start.toUTC(), end.toUTC())
  );
  const rollingStartDate = DateTime.fromSeconds(rollingDatas[0].startDate);

  const operations = relativeOperations.map(({ interval, ...other }) => ({
    ...other,
    date: rollingStartDate
      .plus({ [interval.kind]: interval.amount })
      .toJSDate(),
  }));
  return { data: rollingDatas, operations };
}
